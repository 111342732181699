import React from 'react'
import Navbar2 from '../components/Navbar2'
function Cooperation() {
  return (
    <div>
      <Navbar2/>
    </div>
  )
}

export default Cooperation
